import React from "react";
import Toolbar from "../../components/toolbar/toolbar";
import labels from "../../utils/constants/labels";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Networking from "../../utils/api/apiaccess";
import ApiValues from '../../api/url/apiurl';
// import Toolbar from "../../components/toolbar/toolbar";
import Summary from "../../components/summary/index";
import PortfolioXRay from "../portfolio-x-ray/portfolio-x-ray";
import Transactionreport from "../transaction/transactionreport";
import Profitlossreport from "../../components/table/profitlossreport";
// import { GetPortfolioXray } from "../../redux/actions/portfolioxray/portfolioxray";
import getFamilyXrayReport from "../../api/portfolioxrayreport/familyxrayapi";
// import getfamilytr
import getFamilyTransactionList from "../../api/transactionreport/familytransactionreportdetails";
import getAllFamilyProfitLossDetails from "../../api/profitloss/familyprofitlossapi";
import { replaceNull, sortArray } from "../../utils/commonfunction";
import { getMaturityTrackerDetails } from '../../redux/actions/maturitytracker/maturitytrackeraction'
import PropTypes from 'prop-types';
import { NegativeFinding } from "../../utils/commonfunction";
import { getFamilyCategoryAllocation } from "../../api/summary/familycategoryallocation";
// import { getFamilyCategoryAllocation } from "../summary/familycategoryallocation";
import getProductWisePortfolioSummaryFamily from "../../api/cardmenu/productwiseportfoliosummaryfamily";
import moment from "moment";
import MaturityTracker from "../maturity_tracker/maturitytracker";
import { DateRange } from "@material-ui/icons";
import Nps_layout from "../layout/nps_layout";
import Liquiloans_layout from "../layout/liquiloans_layout";
import InsuranceLayout from '../layout/insurance_layout';
import Liquiloans_tab from "../tabs/liquiloans_tab";
import getAllTransferIn from "../../api/transferin/transferin";
import constants from "../../utils/constants/apiconstant";
import Insurance_tabs from "../tabs/insurance_tabs";
import getAllMaturityTracker from "../../api/maturitytracker/maturitytrackerapi";
import getFamilyLiquiloans from "../../api/structuredproducts/familyliquiloans";
import getFamilyLiquiloansHeader from "../../api/structuredproducts/familyliquiloansheader";
import getFamilyMaturityTracker from "../../api/maturitytracker/familymaturitytracker";
import getFamilyTransferIn from "../../api/transferin/familytransferin";
// import labels from "../../utils/constants/labels";
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelsledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                // <Typography>{children}</Typography>      
                <div
                    style={{
                        fontSize: '1rem',
                        fontWeight: 400,
                        lineHeight: 1.5,
                        letterSpacing: '0.00938rem',
                    }}
                >{children}</div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
const styles = (theme) => ({
    root: {
        // flexGrow: 1,
        // width: '100%',
        display: "grid",
        gridTemplateColumns: "10% 81% 9%",
        // paddingTop: 5,
        // display: 'grid',
        marginTop: 51,
        // // padding: 10,
        // // backgroundColor: window.globalConfig.color.BLUE_BACKGROUND,
        // backgroundColor: window.globalConfig.color.WHITE,
        // gridTemplateColumns: '100%'
        // // backgroundColor: theme.palette.background.paper,
    },
    tabRoot: {
        "& .MuiTab-root:focus": {
            color: window.globalConfig.color.BROWN
        },
        "& .MuiTab-textColorPrimary.Mui-selected": {
            color: window.globalConfig.color.BROWN
        }
    },
    portfolioXrayCatrgorySevenSplitContainer: {
        display: "grid",
        gridTemplateColumns: "30% 12% 12% 13% 13% 11% 9% !important",
        // paddingLeft: "10px"
        // marginLeft: "20px",
        minHeight: "50px !important",
        alignContent: "center",
        "& .schemeLine":
        {
            textAlign: "right", paddingRight: 13
        },
        [theme.breakpoints.down("920")]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr) !important',
            gridTemplateAreas: `"categorySchemeGrid categoryMarketValueGrid"
       `,
            "& .categoryAbsoluteGainLossGrid": {
                display: "none"
            },
            "& .categoryUnitGrid": {
                display: "none"
            }
        }
    },
    portfolioXraySevenSplitContainer: {
        display: "grid",
        gridTemplateColumns: "30% 12% 12% 13% 13% 11% 9% !important",
        // paddingLeft: "10px"
        // marginLeft: "20px",
        alignContent: "center",
        [theme.breakpoints.down("920")]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr) !important',
            gap: 1,
            gridTemplateRows: 'auto',
            paddingRight: 10,
            paddingTop: 10,
            gridTemplateAreas: `"schemeFirstGrid schemeFifthGrid  myLastGrid"
    "schemeSecondGrid . schemeSixthGrid"
    "schemeThirdGrid . investedAmountGrid"`,
            "& .schemeFourthGrid": {
                display: "none"
            }
            , "& .categoryAbsoluteGainLossGrid": {
                display: "none"
            },
            "& .categoryMarketValueGrid": {
                display: "none !important"
            }
        },
    },
    // portfolioXraySchemeCatrgorySevenSplitContainer: {
    //   display: "grid",
    //   gridTemplateColumns: "30% 12% 12% 12% 11% 11% 11% !important",
    //   // paddingLeft: "10px"
    //   // marginLeft: "20px",
    //   alignContent: "center",
    //   [theme.breakpoints.down("920")]: {
    //     display: 'grid',
    //     gridTemplateColumns: 'repeat(2, 1fr) !important',
    //     gridTemplateAreas: `"categorySchemeGrid categoryMarketValueGrid"
    //    `,
    //     "& .categoryAbsoluteGainLossGrid": {
    //       display: "none"
    //     },
    //     "& .categoryUnitGrid": {
    //       display: "none"
    //     }
    //   }
    // },
    // portfolioXraySchemeSevenSplitContainer: {
    //   display: "grid",
    //   gridTemplateColumns: "30% 12% 12% 12% 11% 11% 11% !important",
    //   // paddingLeft: "10px"
    //   // marginLeft: "20px",
    //   alignContent: "center",
    //   [theme.breakpoints.down("920")]: {
    //     display: 'grid',
    //     gridTemplateColumns: 'repeat(2, 1fr) !important',
    //     gap: 1,
    //     gridTemplateRows: 'auto',
    //     paddingRight: 10,
    //     paddingTop: 10,
    //     gridTemplateAreas: `"schemeFirstGrid schemeFifthGrid  myLastGrid"
    // "schemeSecondGrid . schemeSixthGrid"
    // "schemeThirdGrid . investedAmountGrid"`,
    //     "& .schemeFourthGrid": {
    //       display: "none"
    //     }
    //     , "& .categoryAbsoluteGainLossGrid": {
    //       display: "none"
    //     },
    //     "& .categoryMarketValueGrid": {
    //       display: "none !important"
    //     }
    //   },
    // },
    transactionContainer: {
        display: "grid",
        gridTemplateColumns: "10% 24% 12% 10% 10% 10% 11% 8% 5%",
        marginLeft: "20px",
        alignContent: "center",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(2,1fr)",
            gridTemplateAreas: `"${"dategrid"} ${"dategrid"}"
        "${"schemegrid"} ${"schemegrid"}"
        "${"assetClassgrid"} ${"transactionTypegrid"}"
        "${"folioNumbergrid"} ${"unitgrid"}"
        "${"navgrid"} ${"amountgrid"}"`,
            padding: 10,
            gap: 10
        }
    },
    toolbarcard: {
        position: 'relative',
        marginBottom: '-3%',
        gridTemplateColumns: "17% 16% 16% 16% 15% 20%"
    },
    portfolioTwoSplitContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(2,1fr)",
        [theme.breakpoints.down("920")]: {
            backgroundColor: "transparent !important",
            minHeight: "4% !important",
            alignContent: "end",
            marginTop: "1% !important"
        }
    },
    BookedglContainer: {
        display: "grid",
        gridTemplateColumns: "25% 7% 14% 14% 10% 15% 15%",
        marginLeft: "7px",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(2,1fr)",
            gridTemplateAreas: `"${"firstGrid"} ${"sixthGrid"}"
                             "${"thirdGrid"} ${"fourthGrid"}"
                             "${"secondGrid"} ${"fifthGrid"}"`,
        }
    },
    portfolioXrayCatrgorySevenSplitContainer: {
        display: "grid",
        gridTemplateColumns: "27% 12% 14% 14% 14% 11% 8% !important",
        // paddingLeft: "10px"
        // marginLeft: "20px",
        alignContent: "center",
        [theme.breakpoints.down("920")]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr) !important',
            gridTemplateAreas: `"categorySchemeGrid categoryMarketValueGrid"
         `,
            "& .categoryAbsoluteGainLossGrid": {
                display: "none"
            },
            "& .categoryUnitGrid": {
                display: "none"
            }
        }
    },
    portfolioXraySevenSplitContainer: {
        display: "grid",
        gridTemplateColumns: "27% 12% 14% 14% 14% 11% 8% !important",
        // paddingLeft: "10px"
        // marginLeft: "20px",
        alignContent: "center",
        [theme.breakpoints.down("920")]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr) !important',
            gap: 1,
            gridTemplateRows: 'auto',
            paddingRight: 10,
            paddingTop: 10,
            gridTemplateAreas: `"schemeFirstGrid schemeFifthGrid  myLastGrid"
      "schemeSecondGrid . schemeSixthGrid"
      "schemeThirdGrid . investedAmountGrid"`,
            "& .schemeFourthGrid": {
                display: "none"
            }
            , "& .categoryAbsoluteGainLossGrid": {
                display: "none"
            },
            "& .categoryMarketValueGrid": {
                display: "none !important"
            }
        },
    },
    bondsTransferinPortfolioXray: {
        display: "grid",
        gridTemplateColumns: "33% 33% 33% !important",
        "& .investedAmountGrid": {
            display: "none"
        },
        "& .myLastGrid": {
            display: "none"
        },
        "& .schemeFifthGrid": {
            display: "none"
        },
        "& .schemeSixthGrid": {
            display: "none"
        },
        [theme.breakpoints.down("920")]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr) !important',
            gap: 10,
            gridTemplateRows: 'auto',
            paddingRight: 10,
            gridTemplateAreas: `"schemeFirstGrid ."
      "schemeFourthGrid  schemeThirdGrid"
      `,
            "& .schemeFourthGrid": {
                alignItems: "flex-start"
            },
            "& .schemeThirdGrid": {
                alignItems: "flex-end"
            }
        },
    },
    bondsTransferinPortfolioXrayCategory: {
        display: "grid",
        gridTemplateColumns: "33% 33% 33% !important",
        // paddingLeft: "10px"
        //marginLeft: "20px",
        alignContent: "center",
        "& .categoryAbsoluteGainLossGrid": {
            display: "none"
        },
        [theme.breakpoints.down("920")]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr) !important',
            gridTemplateAreas: `"categorySchemeGrid ."
          "categoryInvestedGrid"
         `,
            "& .categoryNetpl": {
                display: "none"
            }
        }
    },
    equityPortfolioXrayCatrgorySevenSplitContainer: {
        display: "grid",
        gridTemplateColumns: "32% 12% 14% 14% 14% 14%!important",
        // paddingLeft: "10px"
        marginLeft: "20px",
        alignContent: "center",
        [theme.breakpoints.down("920")]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr) !important',
            gridTemplateAreas: `"categorySchemeGrid categoryInvestedGrid"
          ". categoryNetpl"
         `,
        }
    },
    equityPortfolioXraySevenSplitContainer: {
        display: "grid",
        gridTemplateColumns: "35% 10% 13% 14% 14% 14%",
        marginLeft: 20,
        [theme.breakpoints.down("920")]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr) !important',
            gap: 1,
            gridTemplateRows: 'auto',
            paddingRight: 10,
            gridTemplateAreas: `"schemeFirstGrid  . schemeFifthGrid"
      "schemeSecondGrid . schemeSixthGrid"
      "schemeThirdGrid . investedAmountGrid"`,
            "& .schemeFourthGrid": {
                display: "none"
            }
        },
    },
    cardsData: {
        display: "grid",
        gridTemplateColumns: "repeat(3,1fr)",
    },
    bondsPortfolioXrayCatrgorySevenSplitContainer: {
        display: "grid",
        gridTemplateColumns: "25% 15% 15% 15% 15% 15% !important",
        // paddingLeft: "10px"
        marginLeft: "20px",
        alignContent: "center",
        [theme.breakpoints.down("920")]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr) !important',
            gridTemplateAreas: `"categorySchemeGrid categoryInvestedGrid"
          ". categoryNetpl"
         `,
        }
    },
    bondsPortfolioXraySevenSplitContainer: {
        display: "grid",
        gridTemplateColumns: "25% 15% 15% 15% 15% 15%",
        marginLeft: 20,
        [theme.breakpoints.down("920")]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr) !important',
            gap: 1,
            gridTemplateRows: 'auto',
            paddingRight: 10,
            gridTemplateAreas: `"schemeFirstGrid  . schemeFifthGrid"
      "schemeSecondGrid . schemeSixthGrid"
      "schemeThirdGrid . investedAmountGrid"`,
            "& .schemeFourthGrid": {
                display: "none"
            }
        },
    },
    fdPortfolioXrayCatrgorySevenSplitContainer: {
        display: "grid",
        gridTemplateColumns: "36% 0% 16% 16% 16% 16% !important",
        // paddingLeft: "10px"
        marginLeft: "20px",
        alignContent: "center",
        [theme.breakpoints.down("920")]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr) !important',
            gridTemplateAreas: `"categorySchemeGrid categoryInvestedGrid"
          ". categoryNetpl"
         `,
        }
    },
    fdPortfolioXraySevenSplitContainer: {
        display: "grid",
        gridTemplateColumns: "36% 16% 16% 16% 16%",
        marginLeft: 20,
        [theme.breakpoints.down("920")]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr) !important',
            gap: 1,
            gridTemplateRows: 'auto',
            paddingRight: 10,
            gridTemplateAreas: `"schemeFirstGrid  . schemeFifthGrid"
      "schemeSecondGrid . schemeSixthGrid"
      "schemeThirdGrid . investedAmountGrid"`,
            "& .schemeFourthGrid": {
                display: "none"
            }
        },
    },
    aifPortfolioXrayCatrgorySevenSplitContainer: {
        display: "grid",
        gridTemplateColumns: "35% 16% 16% 16% 16% !important",
        // paddingLeft: "10px"
        //marginLeft: "20px",
        alignContent: "center",
        [theme.breakpoints.down("920")]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr) !important',
            gridTemplateAreas: `"categorySchemeGrid categoryInvestedGrid"
          ". categoryNetpl"
         `,
        }
    },
    aifPortfolioXraySevenSplitContainer: {
        display: "grid",
        gridTemplateColumns: "35% 16% 16% 16% 16%",
        // marginLeft: 20,
        [theme.breakpoints.down("920")]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr) !important',
            gap: 1,
            gridTemplateRows: 'auto',
            paddingRight: 10,
            gridTemplateAreas: `"schemeFirstGrid  . schemeFifthGrid"
      "schemeSecondGrid . schemeSixthGrid"
      "schemeThirdGrid . investedAmountGrid"`,
            "& .schemeFourthGrid": {
                display: "none"
            }
        },
    },
    mldPortfolioXraySevenSplitContainer: {
        display: "grid",
        gridTemplateColumns: "35% 16% 16% 16% 16%",
        marginLeft: 20,
        [theme.breakpoints.down("920")]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr) !important',
            gap: 1,
            gridTemplateRows: 'auto',
            paddingRight: 10,
            gridTemplateAreas: `"schemeFirstGrid  schemeFirstGrid   schemeFifthGrid"
          "schemeSecondGrid . schemeSixthGrid"
          "schemeThirdGrid . investedAmountGrid"`,
            "& .schemeFourthGrid": {
                display: "none"
            }
        },
    },
    mldTransactionFifthContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(4,1fr)",
        marginLeft: "20px",
        alignContent: "center",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(2,1fr)",
            gridTemplateAreas: `"${"dategrid"} ${"dategrid"}"
          "${"schemegrid"} ${"schemegrid"}"
          "${"assetClassgrid"} ${"transactionTypegrid"}"
          "${"folioNumbergrid"} ${"unitgrid"}"
          "${"navgrid"} ${"amountgrid"}"`,
            padding: 10,
            gap: 10
        }
        //     gridTemplateAreas: `"schemeFirstGrid  . schemeFifthGrid"
        // "schemeSecondGrid . schemeSixthGrid"
        // "schemeThirdGrid . investedAmountGrid"`,
    },
    mldPortfolioXrayCatrgorySevenSplitContainer: {
        display: "grid",
        gridTemplateColumns: "35% 0% 16% 16% 16% 16% !important",
        // paddingLeft: "10px"
        marginLeft: "20px",
        alignContent: "center",
        [theme.breakpoints.down("920")]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr) !important',
            gridTemplateAreas: `"categorySchemeGrid categoryInvestedGrid"
          ". categoryNetpl"
         `,
        }
    },
    equityTransactionContainer: {
        display: "grid",
        gridTemplateColumns: "10% 36%  10% 10% 17% 17%",
        marginLeft: "20px",
        alignContent: "center",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(2,1fr)",
            gridTemplateAreas: `"${"dategrid"} ${"dategrid"}"
          "${"schemegrid"} ${"schemegrid"}"
          "${"assetClassgrid"} ${"transactionTypegrid"}"
          "${"folioNumbergrid"} ${"unitgrid"}"
          "${"navgrid"} ${"amountgrid"}"`,
            padding: 10,
            gap: 10
        }
    },
    bondTransactionContainer: {
        display: "grid",
        gridTemplateColumns: "10% 37% 16% 12% 12% 13%",
        marginLeft: "20px",
        alignContent: "center",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(2,1fr)",
            gridTemplateAreas: `"${"dategrid"} ${"dategrid"}"
          "${"schemegrid"} ${"schemegrid"}"
          "${"assetClassgrid"} ${"transactionTypegrid"}"
          "${"folioNumbergrid"} ${"unitgrid"}"
          "${"navgrid"} ${"amountgrid"}"`,
            padding: 10,
            gap: 10
        }
    },
    fdTransactionContainer: {
        display: "grid",
        gridTemplateColumns: "10% 35% 10% 10% 15% 20%",
        marginLeft: "20px",
        alignContent: "center",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(2,1fr)",
            gridTemplateAreas: `"${"dategrid"} ${"dategrid"}"
          "${"schemegrid"} ${"schemegrid"}"
          "${"assetClassgrid"} ${"transactionTypegrid"}"
          "${"folioNumbergrid"} ${"unitgrid"}"
          "${"navgrid"} ${"amountgrid"}"`,
            padding: 10,
            gap: 10
        }
    },
    aifTransactionContainer: {
        display: "grid",
        gridTemplateColumns: "15% 40% 20% 25%",
        marginLeft: "20px",
        alignContent: "center",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(2,1fr)",
            gridTemplateAreas: `"${"dategrid"} ${"dategrid"}"
          "${"schemegrid"} ${"schemegrid"}"
          "${"assetClassgrid"} ${"transactionTypegrid"}"
          "${"folioNumbergrid"} ${"unitgrid"}"
          "${"navgrid"} ${"amountgrid"}"`,
            padding: 10,
            gap: 10
        }
    }
});
// var colorarray = labels.chartColor;
let MenuList;
const transferIntitle = [{ "name": labels.Tableheader.ScripName }, { "name": labels.Tableheader.quantity, }, { "name": labels.Tableheader.CurrentValue, },]

class FamilyReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            anchorEl: null,
            transferInLoader: false,
            transferIn: [],
            productName: "",
            productID: 1,
            portfolioSummary: [],
            categoryChart: [],
            categoryTable: [],
            splitByCategoryEquity: [],
            splitByCategoryDebt: [],
            sectorAllocation: [],
            portfolioXray: [],
            portfolioXrayBKP: [],
            initialClick: false,
            transaction: [],
            transactionBKP: [],
            startDate: "",
            endDate: "",
            profitLoss: [],
            profitLossBKP: [],
            loaderCategoryChart: false,
            loaderCategoryTable: false,
            loaderSplitByCategoryEquity: false,
            loaderSplitByCategoryDebt: false,
            loaderSectorAllocation: false,
            loaderPortfolioXray: false,
            loaderTransaction: false,
            loaderProfitAndLoss: false,
            loaderPortfolioSummary: false,
            selectedProduct: [],
            maturityTrackerList: [],
            maturityTrackerBKP: [],
            isLoadingMaturity: false
            , isLoadingLiquiloans: false,
            portfolioDetails_liquiloans: [],
            bkp_PortfolioDetails_liquiloans: [],
            transaction_liquiloans: [],
        }
    }
    componentDidMount() {
        // this.summaryData();
        // this.portfolioXrayData();
        // this.transactionData();
        // this.profitLossData();
        // this.getPortfolioSummary();
        this.handleMenuListClick({ name: "Mutual Funds", id: 1, isTabEnabled: true, })
    }
    handleMenuClick = (event) => {
        this.setState({ anchorEl: event.currentTarget })
        // setAnchorEl3(event.currentTarget);
    };
    handleMenuListClick = async (val) => {

        if (this.state.productName == val.name) {
        } else {
            await this.setState({ productName: val.name, productID: val.id, selectedProduct: val, value: 0 })
            this.summaryData();
            if (val.id != 9 && val.id != 12) {
                this.getPortfolioSummary();
            }
            this.portfolioXrayData();
            this.transactionData();
            this.profitLossData();
            if (val.id == 2 || val.id == 3) {
                this.transferInReport()
            }
            if (val.id == 5) {
                this.getMaturityDetails()
            }
            if (val.id == 12) {
                this.getLiquiloansDate()
            }
        }
        this.handleMenuClose();
    }
    getMaturityDetails = () => {
        this.setState({ isLoadingMaturity: true })
        getFamilyMaturityTracker(this.props.logindetails.ClientID, 0, moment(this.props.date, 'DD MMM YYYY').format("DD MMM YYYY"), this.state.productID, 0, this.props.L5ID, 0,).then((res) => {
            this.setState({ isLoadingMaturity: false })
            if (res.status == "S") {
                this.setState({ maturityTrackerList: replaceNull(res.data, "NA"), maturityTrackerBKP: replaceNull(res.data, "NA") })
            } else {
                this.setState({ maturityTrackerList: [], maturityTrackerBKP: [] })
            }
        })
    }
    handleMenuClose = () => {
        this.setState({ anchorEl: null })
    }
    componentDidUpdate(prev, prevState) {
        const { logindetails, date, L5ID } = this.props
        // if (this.props.showDateDiff() < 1) {
        if (prev.date != date) {
            this.profitLossData();
            this.portfolioXrayData();
            this.summaryData();
            if (this.state.selectedProduct.id != 9 && this.state.selectedProduct.id != 12) {
                this.getPortfolioSummary();
            }
            this.transactionData();
            if (this.state.selectedProduct.id == 2 || this.state.selectedProduct.id == 3) {
                this.transferInReport()
            }
            if (this.state.selectedProduct.id == 5) {
                this.getMaturityDetails()
            }
            if (this.state.selectedProduct.id == 12) {
                this.getLiquiloansDate()
            }
        }
        // }
    }
    getPortfolioSummary = () => {
        // console.log(this.state.productID)
        this.setState({ loaderPortfolioSummary: true })
        getProductWisePortfolioSummaryFamily(this.props.clientID, this.props.date, this.props.date).then((res) => {
            this.setState({ loaderPortfolioSummary: false })
            if (res.status == "S" && res.mfHeader2 != null && res.mfHeader2.length != 0) {
                let data = replaceNull(res.mfHeader2, "NA")
                this.setState({
                    portfolioSummary: data.length == 0 ? [] : [
                        { firstName: 'Portfolio Value', lastName: data.filter((item) => item.ProductID == this.state.productID).length == 0 ? 0 : NegativeFinding(data.filter((item) => item.ProductID == this.state.productID)[0].PortFolioNetworth, "", "", "", "rupee") || "NA" },
                        { firstName: 'Invested Amount', lastName: data.filter((item) => item.ProductID == this.state.productID).length == 0 ? 0 : NegativeFinding(data.filter((item) => item.ProductID == this.state.productID)[0].InvestmentValue, "", "", "", "rupee") || "NA" },
                        { firstName: 'Current Value', lastName: data.filter((item) => item.ProductID == this.state.productID).length == 0 ? 0 : NegativeFinding(data.filter((item) => item.ProductID == this.state.productID)[0].MFPortFolioNetworth, "", "", "", "rupee") || "NA" },
                        { firstName: 'Unrealized G/L', lastName: data.filter((item) => item.ProductID == this.state.productID).length == 0 ? 0 : NegativeFinding(data.filter((item) => item.ProductID == this.state.productID)[0].UnRealizedGainLoss, "", "", "", "rupee") || "NA" },
                        { firstName: 'Dividend', lastName: data.filter((item) => item.ProductID == this.state.productID).length == 0 ? 0 : NegativeFinding(data.filter((item) => item.ProductID == this.state.productID)[0].Dividend, "", "", "", "rupee") || "NA" },
                        { firstName: 'Net G/L (%)', lastName: data.filter((item) => item.ProductID == this.state.productID).length == 0 ? 0 : NegativeFinding(data.filter((item) => item.ProductID == this.state.productID)[0].RealizedGainLoss, "", "", "", "rupee") || "NA", val: "(" + data.filter((item) => item.ProductID == this.state.productID)[0]?.UnRealizedGainLossPercent + "%)", checkPercentage: data.filter((item) => item.ProductID == this.state.productID)[0]?.UnRealizedGainLossPercent },
                    ]
                })
            } else {
                this.setState({ portfolioSummary: [] })
            }
        })
    }
    getLiquiloansDate = () => {
        let data = {
            ClientID: this.props.logindetails.ClientID,
            L4ID: 0,
            L5ID: this.props.L5ID,
            AsOnDate: this.props.date
        }
        this.setState({ isLoadingLiquiloans: true, loaderPortfolioSummary: true })
        getFamilyLiquiloansHeader(data).then((response) => {
            let res = replaceNull(response, "NA")
            this.setState({ loaderPortfolioSummary: false })

            if (res.status == "S" && res.data != "NA") {
                this.setState({
                    portfolioSummary: [{ firstName: "Portfolio Value", lastName: NegativeFinding(res.data[0].PortfolioValue, "", "", "", "rupee") },
                    { firstName: "Investment Amount", lastName: NegativeFinding(res.data[0].InvestmentAmount, "", "", "", "rupee") },
                    { firstName: "Current Value", lastName: NegativeFinding(res.data[0].CurrentValue, "", "", "", "rupee"), val: `(${NegativeFinding(res.data[0].XIRR, "", "", "", "percentage")})` }
                    ]
                })
            } else {
                this.setState({
                    portfolioSummary: [{ firstName: "Portfolio Value", lastName: NegativeFinding(0, "", "", "", "rupee") },
                    { firstName: "Investment Amount", lastName: NegativeFinding(0, "", "", "", "rupee") },
                    { firstName: "Current Value", lastName: NegativeFinding(0, "", "", "", "rupee"), val: `(${NegativeFinding(0, "", "", "", "percentage")})` }
                    ]
                })
            }
        })

    }
    portfolioXrayData = () => {
        this.setState({ loaderPortfolioXray: true })
        getFamilyXrayReport(this.props.clientID, this.props.date, this.props.logindetails.SessionID, this.state.productID).then((res) => {
            this.setState({ loaderPortfolioXray: false })
            if (res.status == "S" && res.data != null) {
                this.setState({
                    portfolioXray: replaceNull(res.data, "NA").map(({ ...item }) => {
                        if (this.state.productID != 1) {
                            delete item.AbsoluteGainORLossPercentage
                        }
                        if (this.state.productID == 5 || this.state.productID == 6) {
                            delete item.AbsoluteGainOrLoss
                            delete item.realizedGainLossValuePercentage
                            delete item.TotalAbsoluteGainOrLoss
                        }
                        if (this.state.productID == 10) {
                            delete item.Units
                        }
                        return { ...item, Scheme: item.Scheme == undefined ? item.ScripName : item.Scheme, CategoryUnits: "" }
                    }),
                    portfolioXrayBKP: replaceNull(res.data, "NA").map(({ ...item }) => {
                        if (this.state.productID != 1) {
                            delete item.AbsoluteGainORLossPercentage
                        }
                        if (this.state.productID == 5 || this.state.productID == 6) {
                            delete item.AbsoluteGainOrLoss
                            delete item.realizedGainLossValuePercentage
                            delete item.TotalAbsoluteGainOrLoss
                        }
                        if (this.state.productID == 10) {
                            delete item.Units
                        }
                        return { ...item, Scheme: item.Scheme == undefined ? item.ScripName : item.Scheme, CategoryUnits: "" }
                    })
                })

            } else {
                this.setState({ portfolioXray: [], portfolioXrayBKP: [] })
            }
        })
    }
    transactionData = () => {
        this.setState({ loaderTransaction: true })
        getFamilyTransactionList(this.props.clientID, this.props.date, this.props.logindetails.SessionID, this.state.productID).then((res) => {
            this.setState({ loaderTransaction: false })
            if (res.status == "S" && res.data != null && res.data.length != 0) {
                let dates = []
                res.data.map((value) => {
                    dates.push(new Date(moment(value.Date).format("YYYY/MM/DD")));
                });
                const max = moment(new Date(Math.max.apply(null, dates))).format(
                    "DD MMM YYYY"
                );
                const min = moment(new Date(Math.min.apply(null, dates))).format(
                    "DD MMM YYYY"
                );
                this.setState({
                    startDate: min,
                    endDate: max,
                    transaction: replaceNull(res.data, "NA").map((item) => {
                        if (this.state.selectedProduct.id == 10 || this.state.selectedProduct.id == 6) {
                            delete item.Unit
                            delete item.NAV
                        }
                        return { ...item, StampDuty: item.StampDuty || 0, STT: item.STT || 0, Load: item.RedemptionLoad || 0 }
                    }), transactionBKP: replaceNull(res.data, "NA").map((item) => {
                        if (this.state.selectedProduct.id == 10 || this.state.selectedProduct.id == 6) {
                            delete item.Unit
                            delete item.NAV
                        }
                        return { ...item, StampDuty: item.StampDuty || 0, STT: item.STT || 0, Load: item.RedemptionLoad || 0 }
                    })
                })
            } else {
                this.setState({ transaction: [], transactionBKP: [] })
            }
        })
    }
    profitLossData = () => {
        this.setState({ loaderProfitAndLoss: true })
        getAllFamilyProfitLossDetails(this.props.clientID, this.props.date, this.props.logindetails.SessionID, this.state.productID).then((res) => {
            this.setState({ loaderProfitAndLoss: false })
            if (res.status == "S" && res.data != null && res.data.length != 0) {
                this.setState({
                    profitLoss: replaceNull(res.data, "NA").map((item) => {
                        return { ...item, Scheme: item.Scheme == undefined ? item.ScripName : item.Scheme }
                    }), profitLossBKP: replaceNull(res.data, "NA").map((item) => {
                        return { ...item, Scheme: item.Scheme == undefined ? item.ScripName : item.Scheme }
                    })
                })
            } else {
                this.setState({ profitLoss: [], profitLossBKP: [] })
            }
        })
        // console.log(this.state.profitLoss)
    }
    summaryData = () => {
        // console.log("zmdbfjkd")
        this.setState({ loaderCategoryTable: true, loaderCategoryChart: true, loaderSectorAllocation: true, loaderSplitByCategoryDebt: false, loaderSplitByCategoryEquity: true })
        getFamilyCategoryAllocation(this.props.clientID, this.props.date, this.props.logindetails.SessionID, this.state.productID).then((res) => {
            if (res.status == "S" && res.summary != null && res.summary.length != 0) {
                let product =
                    res.summary == null ||
                        res.summary == undefined ||
                        res.summary.length == 0 || res.summary == null
                        ? []
                        : res.summary.filter(
                            (ele, ind) =>
                                ind ===
                                res.summary.findIndex(
                                    (elem) => elem.AssetClass === ele.AssetClass
                                )
                        );
                let assetClassMutualFund =
                    product.length != 0
                        ? product.filter((item) => item.ProductId == this.state.productID).map((i) => {
                            return {
                                product: i.AssetClass,
                                productId: i.ProductId,
                                productName: i.Product,
                                value: res.summary.filter(
                                    (item) =>
                                        item.AssetClass == i.AssetClass && item.ProductId == this.state.productID
                                ),
                            };
                        })
                        : [];
                let productColor =
                    product.length != 0
                        ? product.map((i, index) => {
                            return {
                                product: i.AssetClass,
                                color: labels.chartColor[index],
                            };
                        })
                        : [];

                let chart_Data = assetClassMutualFund.length != 0
                    ? assetClassMutualFund
                        .filter((val) => val.productId == this.state.productID)
                        .map((i, index) => {
                            return {
                                name: i.product,
                                value: i.value.reduce(
                                    (a, v) => (a = a + v.InvestmentValue),
                                    0
                                ),
                                color: productColor.filter(
                                    (item) => item.product == i.product
                                )[0].color
                            };
                        })
                    : []
                let grp_product = assetClassMutualFund.length != 0
                    ? assetClassMutualFund
                        .filter((item) => item.productId == this.state.productID)
                        .map((i, index) => {
                            return {
                                id: index,
                                category: i.product,
                                isExpand: true,
                                productName: i.productName,
                                totalCurrentValue: NegativeFinding(i.value[0].TotalCurrentValue, "", "", "", "rupee"),
                                color: productColor.filter(
                                    (item) => item.product == i.product
                                )[0].color,
                                amount: i.value.filter((item) => item.ProductId == this.state.productID)[0]
                                    .CategoryCurrentValue,
                                categoryPercentage: "-",
                                holdingPercentage: i.value.filter(
                                    (item) => item.ProductId == this.state.productID
                                )[0].TotalHoldingPercentage,
                                rowitem: i.value
                                    .filter((item) => item.ProductId == this.state.productID)
                                    .map((item) => {
                                        return {
                                            category: item.SubAssetClass,
                                            amount: item.CurrentValue,
                                            categoryPercentage: item.CategoryPercent,
                                            holdingPercentage: item.HoldingPercent,
                                        };
                                    }),
                            };
                        })
                    : []
                this.setState({
                    categoryTable: grp_product,
                    categoryChart: chart_Data,
                })
            } else {
                this.setState({
                    categoryTable: [],
                    categoryChart: []
                })
            }
            if (res.status == "S" && res.chart != null && res.chart.length != 0) {
                // this.setState({
                //     categoryChart: replaceNull(res.chart).filter((item) => item.ProductId == this.state.productID).map((item, index) => {
                //         return {
                //             ...item, name: item.Product, value: item.Percentofallocation, color: labels.chartColor[index]
                //         }
                //     })
                // }) 
            } else {
                // this.setState({ categoryChart: [] })
            }
            this.setState({ loaderCategoryTable: false, loaderCategoryChart: false })
        })
    }
    handleChange = (event, newValue) => {
        this.setState({ value: newValue })
        this.transactionFilterSubmit([])
    };
    transactionFilterSubmit = (value) => {
        let data = this.state.transactionBKP
        let modifiedData = data;
        let scheme = [];
        let transaction = [];
        let asset = [];
        if (value.length != 0) {
            if (value.Schemes.length != 0) {
                scheme = value.Schemes.map((item) => {
                    return item.label
                })
            }
            if (value.Asset.length != 0) {
                asset = value.Asset.map((item) => {
                    return item.label
                })
            }
            if (value.Transaction.length != 0) {
                transaction = value.Transaction.map((item) => {
                    return item.label
                })
            }
            if (value.financialYear != "" &&
                value.financialYear != null &&
                value.financialYear != undefined) {
                var datespliting = value.financialYear.split("to");
                var date1 = new Date(datespliting[0].trim()).getTime();
                var date2 = new Date(datespliting[1].trim()).getTime();
                modifiedData = modifiedData.filter((data) => {
                    var time = new Date(data.SortDate).getTime();
                    return time >= date1 && time <= date2;
                });
            }
            // console.log(scheme, modifiedData)
            if (scheme.length != 0) {
                modifiedData = modifiedData.filter((data) => {
                    return scheme.includes(data.Scheme);
                });
            }
            if (transaction.length != 0) {
                modifiedData = modifiedData.filter((data) => {
                    return transaction.includes(data.TransactionType);
                });
            }
            if (asset.length != 0) {
                modifiedData = modifiedData.filter((data) => {
                    return asset.includes(data.AssetClass);
                });
            }
            if (Boolean(value.startDate) || Boolean(value.endDate)) {
                var date1 = new Date(value.startDate).getTime();
                var date2 = new Date(value.endDate).getTime();
                modifiedData = modifiedData.filter((data) => {
                    var time = new Date(data.SortDate).getTime();
                    return time >= date1 && time <= date2;
                });
            }
        }
        this.setState({ transaction: [...modifiedData] })
    }
    maturityFilterSubmit = (value) => {
        let data = this.state.maturityTrackerBKP
        let modifiedData = data;
        let issuerName = [];
        let status = [];
        let asset = [];
        // console.log(value)
        if (!Array.isArray(value)) {
            if (
                value.issuerName.length != 0 &&
                value.issuerName != null &&
                value.issuerName != undefined
            ) {
                issuerName = value.issuerName.map((item) => {
                    return item.label
                })
            }

            if (
                value.status.length != 0 &&
                value.status != null &&
                value.status != undefined
            ) {
                status = value.status.map((item) => {
                    return item.label
                })
            }

            // if (value.financialYear != "" &&
            //     value.financialYear != null &&
            //     value.financialYear != undefined) {
            //     var datespliting = value.financialYear.split("to");
            //     var date1 = new Date(datespliting[0].trim()).getTime();
            //     var date2 = new Date(datespliting[1].trim()).getTime();
            //     modifiedData = modifiedData.filter((data) => {
            //         var time = new Date(data.SortDate).getTime();
            //         return time >= date1 && time <= date2;
            //     });
            // }
            // console.log(scheme, modifiedData)
            if (issuerName.length != 0) {
                modifiedData = modifiedData.filter((data) => {
                    return issuerName.includes(data.IssuerName);
                });
            }
            if (status.length != 0) {
                modifiedData = modifiedData.filter((data) => {
                    return status.includes(data.Status);
                });
            }
            if (
                value.startDate != 0 &&
                value.startDate != undefined &&
                value.startDate != null
            ) {
                modifiedData = modifiedData.filter((data) => {
                    return data.TransactionDate == value.startDate
                });
            }
            if (
                value.maturityDate != 0 &&
                value.maturityDate != "" &&
                value.maturityDate != "NA" &&
                value.maturityDate != undefined &&
                value.maturityDate != null
            ) {
                modifiedData = modifiedData.filter((data) => {
                    return data.MaturityDate == value.maturityDate
                });
            }

        }
        this.setState({ maturityTrackerList: [...modifiedData] })
    }
    profitLossFilterSubmit = (value) => {
        let data = this.state.profitLossBKP
        let modifyData = data
        // console.log(value.length)
        if (value.length != 0) {
            let scheme = value.Scheme != undefined && value.Scheme.length != 0 ? value.Scheme.map((item) => {
                return item.Scheme
            }) : []
            if (scheme.length != 0) {
                modifyData = data.filter((i) => {
                    return scheme.includes(i.Scheme);
                });
            }
        }
        this.setState({ profitLoss: [...modifyData] })
    }
    portfolioXrayFilterSumbit = (value) => {
        // console.log(value)
        let data = this.state.portfolioXrayBKP
        let modifyData = this.state.portfolioXrayBKP
        if (value.length != 0) {
            let scheme = value.Scheme != undefined && value.Scheme.length != 0 ? value.Scheme.map((item) => {
                return item.Scheme
            }) : []
            let AMC = value.AMC != undefined && value.AMC.length != 0 ? value.AMC.map((item) => {
                return item.AMC
            }) : []
            if (scheme.length != 0) {
                modifyData = data.filter((i) => {
                    return scheme.includes(i.Scheme);
                });
            }
            if (AMC.length != 0) {
                modifyData = data.filter((i) => {
                    return AMC.includes(i.AMC)
                })
            }
        }
        // console.log(modifyData)
        this.setState({ portfolioXray: [...modifyData] })
    }
    portfolioSummaryFamily = (rowId) => {
        let newArray = [...this.state.categoryTable];
        //this.state.amcList=""
        newArray[rowId] = {
            ...newArray[rowId],
            isExpand: !newArray[rowId].isExpand,
        };
        this.setState({ categoryTable: [...newArray] })
    }
    transferInReport = () => {
        if (this.state.transferInLoader == false) {
            this.setState({ transferInLoader: true })
            getFamilyTransferIn(this.props.logindetails.ClientID, this.props.date, this.props.logindetails.SessionID, this.state.productID, this.props.L5ID, 0).then((res) => {
                this.setState({ transferInLoader: false })
                if (res.status == "S" && res.data != null && res.data.length != 0) {
                    this.setState({
                        transferIn: res.data.map((item) => {
                            return { Scheme: item.ScripName, TotalMarketValue: item.TotalCurrentMarketValuation, CurrentMarketValuation: item.CurrentMarketValuation, Units: item.Units }
                        })
                    })
                } else {
                    this.setState({ transferIn: [] })
                }
            }).catch((err) => {
                this.setState({ transferIn: [], transferInLoader: false })
            })
        }
    }
    portfolioXraySortBy = (value, index) => {
        // console.log(value, index)
        var filterData;
        let sortValue;
        if (value == labels.sortBy.A_Z || value == labels.sortBy.Z_A) {
            sortValue = "Scheme"
        }
        if (value == labels.sortBy.returns) {
            sortValue = "XIRR"
        }
        if (value == labels.sortBy.marketValue) {
            sortValue = "CurrentMarketValuation"
        }
        filterData = this.state.portfolioXray.sort((a, b) => {
            if (typeof (a[sortValue]) == "string") {
                var textA = a[sortValue].toUpperCase();
                var textB = b[sortValue].toUpperCase();
                return this.state.initialClick ? textA > textB ? -1 : textA < textB ? 1 : 0 : textA < textB ? -1 : textA > textB ? 1 : 0;
            } else {
                return this.state.initialClick ? a[sortValue] - b[sortValue] : b[sortValue] - a[sortValue];
            }
        });
        this.setState({ initialClick: !this.state.initialClick, portfolioXray: [...filterData], })
    }
    render() {
        const { classes } = this.props;
        const portfolioxraytitle = [{
            title: [{ "name": labels.Tableheader.SchemeName, id: 1 },
            { "name": labels.Tableheader.Units, "subheader": labels.Tableheader.Subheadingunits, id: 4 },
            { "name": labels.Tableheader.InvestedValue, "subheader": labels.Tableheader.amount, id: 5 },
            { "name": labels.Tableheader.CurrentValue, "subheader": labels.Tableheader.Subheadingcurrent, id: 6 },
            { "name": labels.Tableheader.unrealised, "subheader": labels.Tableheader.Realised, id: 7 },
            { "name": labels.Tableheader.Net, "subheader": labels.Tableheader.SubheadingNet, id: 8 }
                , { "name": 'Abs.Ret(%)', subheader: "", id: 9 },]
            , productID: 1,
            style: {
                portfolioXrayCatrgorySevenSplitContainer: classes.portfolioXrayCatrgorySevenSplitContainer,
                portfolioXraySevenSplitContainer: classes.portfolioXraySevenSplitContainer
            }
        },
        {
            title: [{ "name": labels.Tableheader.ScripName }, { "name": labels.Tableheader.quantity, "subheader": labels.Tableheader.Avgprice }, { "name": labels.Tableheader.InvestedValue, "subheader": labels.Tableheader.amount }, { "name": labels.Tableheader.CurrentValue, "subheader": labels.Tableheader.Subheadingcurrent }, { "name": labels.Tableheader.unrealised, "subheader": labels.Tableheader.dividend }, { "name": labels.Tableheader.Net, "subheader": labels.Tableheader.SubheadingNet }]
            , productID: 2,
            style: {
                portfolioXrayCatrgorySevenSplitContainer: classes.equityPortfolioXrayCatrgorySevenSplitContainer,
                portfolioXraySevenSplitContainer: classes.equityPortfolioXraySevenSplitContainer
            }
        },
        {
            title: [{ "name": labels.Tableheader.BondName }, { "name": labels.Tableheader.quantity, "subheader": labels.Tableheader.Avgprice }, { "name": labels.Tableheader.InvestedValue, "subheader": labels.Tableheader.amount }, { "name": labels.Tableheader.CurrentValue, "subheader": labels.Tableheader.Subheadingcurrent }, { "name": labels.Tableheader.unrealised, "subheader": "Interest" }, { "name": labels.Tableheader.Net, "subheader": labels.Tableheader.SubheadingNet }]
            , productID: 3,
            style: {
                portfolioXrayCatrgorySevenSplitContainer: classes.bondsPortfolioXrayCatrgorySevenSplitContainer,
                portfolioXraySevenSplitContainer: classes.bondsPortfolioXraySevenSplitContainer
            }
        }
            , {
            title: [{ "name": labels.Tableheader.AssetName },
            { "name": labels.Tableheader.InvestedValue, "subheader": labels.Tableheader.amount },
            { "name": labels.Tableheader.CurrentValue, "subheader": labels.Tableheader.Subheadingcurrent }, { "name": labels.Tableheader.unrealisedint, "subheader": "Interest Payout" }, { "name": labels.Tableheader.Net, "subheader": labels.Tableheader.ROI }]
            , productID: 5,
            style: {
                portfolioXrayCatrgorySevenSplitContainer: classes.fdPortfolioXrayCatrgorySevenSplitContainer,
                portfolioXraySevenSplitContainer: classes.fdPortfolioXraySevenSplitContainer
            }
        },
        {
            title: [{ "name": labels.Tableheader.AssetName }, { "name": labels.Tableheader.InvestedValue, "subheader": labels.Tableheader.amount }, { "name": labels.Tableheader.CurrentValue, "subheader": labels.Tableheader.Subheadingcurrent }, { "name": labels.Tableheader.redemption, "subheader": "Dividend" }, { "name": labels.Tableheader.Net, "subheader": labels.Tableheader.SubheadingNet }]
            , productID: 6,
            style: {
                portfolioXrayCatrgorySevenSplitContainer: classes.aifPortfolioXrayCatrgorySevenSplitContainer,
                portfolioXraySevenSplitContainer: classes.aifPortfolioXraySevenSplitContainer
            }
        },
        {
            title: [{ "name": labels.Tableheader.AssetName }, { "name": labels.Tableheader.InvestedValue, "subheader": labels.Tableheader.amount }, { "name": labels.Tableheader.CurrentValue, "subheader": labels.Tableheader.Subheadingcurrent }, { "name": labels.Tableheader.redemption, "subheader": "Dividend" }, { "name": labels.Tableheader.Net, "subheader": labels.Tableheader.SubheadingNet }],
            productID: 10,
            style: {
                portfolioXrayCatrgorySevenSplitContainer: classes.mldPortfolioXrayCatrgorySevenSplitContainer,
                portfolioXraySevenSplitContainer: classes.mldPortfolioXraySevenSplitContainer
            }
        }]
        const transactionReportHeader = [{
            title:
                [{ 'name': labels.transactionSummary.date }, { 'name': labels.transactionSummary.schemeName }, { 'name': labels.transactionSummary.assetClass }, { 'name': labels.transactionSummary.transactionType }, { 'name': labels.transactionSummary.folioNumber }, { 'name': labels.transactionSummary.units }, { 'name': labels.transactionSummary.nav }, { 'name': labels.transactionSummary.amount }
                    , { 'name': "STAMP DUTY" },
                { name: "STT" },
                { name: "LOAD" }]
            , productID: 1,
            style: classes.transactionContainer
        },
        {
            title: [{ 'name': labels.transactionSummary.date }, { 'name': labels.transactionSummary.scripName }, { 'name': labels.transactionSummary.assetClass }, { 'name': labels.transactionSummary.transactionType, "subheader": labels.transactionSummary.TransactionNature }, { 'name': labels.transactionSummary.contractno }, { 'name': labels.transactionSummary.quantity }, { 'name': labels.transactionSummary.price }, { 'name': labels.transactionSummary.amount }]
            , productID: 2,
            style: classes.equityTransactionContainer
        },
        {
            title:
                [{ 'name': labels.transactionSummary.date }, { 'name': labels.Tableheader.BondName }, { 'name': labels.transactionSummary.assetClass }, { 'name': labels.transactionSummary.transactionType, "subheader": labels.transactionSummary.TransactionNature }, { 'name': labels.transactionSummary.contractno }, { 'name': labels.transactionSummary.quantity }, { 'name': labels.transactionSummary.price }, { 'name': labels.transactionSummary.amount }]
            , productID: 3,
            style: classes.bondTransactionContainer
        }
            , {
            title:
                [{ 'name': labels.transactionSummary.date }, { 'name': labels.Tableheader.AssetName }, { 'name': labels.transactionSummary.assetClass }, { 'name': labels.transactionSummary.transactionType, "subheader": labels.transactionSummary.TransactionNature }, { 'name': labels.transactionSummary.contractno }, { 'name': labels.transactionSummary.quantity }, { 'name': labels.Tableheader.ROI }, { 'name': labels.transactionSummary.amount }]
            , productID: 5,
            style: classes.fdTransactionContainer
        },
        {
            title: [{ 'name': labels.transactionSummary.date }, { 'name': labels.Tableheader.AssetName }, { 'name': labels.transactionSummary.assetClass }, { 'name': labels.transactionSummary.transactionType, "subheader": labels.transactionSummary.TransactionNature }, { 'name': labels.transactionSummary.contractno }, { 'name': "" }, { 'name': "" }, { 'name': labels.transactionSummary.amount }]
            , productID: 6,
            style: classes.aifTransactionContainer
        },
        {
            title: [{ 'name': labels.transactionSummary.date }, { 'name': labels.Tableheader.AssetName }, { 'name': labels.transactionSummary.assetClass }, { 'name': labels.transactionSummary.transactionType, "subheader": labels.transactionSummary.TransactionNature }, { 'name': labels.transactionSummary.contractno }, { 'name': "" }, { 'name': "" }, { 'name': labels.transactionSummary.amount }],
            productID: 10,
            style: classes.mldTransactionFifthContainer
        }]
        MenuList = [{
            name: "Mutual Funds",
            id: 1,
            header: "Scheme",
            Value: "Mutual Funds",
            isTabEnabled: true,
            // cardData: this.props.mutualfund,
            List: [{ name: "Summary" }, { name: "Portfolio X-Ray" },
            { name: "Transactions" }, { name: "Booked G/L" },
            ],
        },
        {
            name: "Stocks / ETF",
            Value: "Stocks / ETF",
            id: 2,
            header: "Scrip",
            isTabEnabled: true,
            // cardData: this.props.stocks,
            List: [{ name: "Summary" }, { name: "Portfolio X-Ray" },
            { name: "Transactions" }, { name: "Booked G/L" },
            { name: "Transfer In" }
            ],
        },
        {
            name: "Bonds",
            Value: "Bonds",
            id: 3,
            header: "Bond",
            isTabEnabled: true,
            List: [{ name: "Summary" }, { name: "Portfolio X-Ray" },
            { name: "Transactions" }, { name: "Booked G/L" },
            { name: "Transfer In" }
            ],
        },
        {
            name: "Fixed Income",
            Value: "Fixed Income",
            id: 5,
            header: "Asset",
            isTabEnabled: true,
            List: [{ name: "Summary" }, { name: "Portfolio X-Ray" },
            { name: "Transactions" }, { name: "Booked G/L" },
            { name: "Maturity Tracker" }
            ],
        },
        {
            name: "AIF / PMS",
            Value: "AIF / PMS",
            id: 6,
            header: "Asset",
            isTabEnabled: true,
            List: [{ name: "Summary" }, { name: "Portfolio X-Ray" },
            { name: "Transactions" }, { name: "Booked G/L" }],
        },
        {
            name: "NPS",
            id: 8,
            cardData: this.state.nps,
            List: [],
            isTabEnabled: false,
            component: <Nps_layout page="FamilyReport" handleMenuClick={this.handleMenuClick} name={"NPS"} handleMenuClose={this.handleMenuClose} handleMenuListClick={this.handleMenuListClick} MenuList={MenuList} anchorEl={this.state.anchorEl} loader={this.state.loaderPortfolioSummary} title={labels.ManuHeading.familyPortfolio} isL5Enabled={false} childFunc={this.props.childFunc}></Nps_layout>
        },
        {
            name: "Insurance",
            id: 9,
            isTabEnabled: false,
            List: [{ name: "Health Insurance" }, { name: "General Insurance" },
            { name: "Life Insurance" },],
            component: <div className={classes.root}>
                <div></div> <Insurance_tabs report="FamilyReport" page={{ state: { tabValue: 0 } }
                }></Insurance_tabs><div></div></div>
        },
        {
            name: "MLD",
            id: 10,
            Value: "MLD",
            isTabEnabled: true,
            header: "Scheme",
            // cardData: this.props.mld,
            List: [{ name: "Summary" }, { name: "Portfolio X-Ray" }, { name: "Transactions" }, { name: "Booked G/L" }
            ],
        },
        {
            name: "Liquiloans",
            id: 12,
            List: [{ name: "Portfolio Details" }, { name: "Transactions" },
            ],
            isTabEnabled: false,
            component: <div className={classes.root}>
                <div></div>
                <Liquiloans_tab page={{ state: { tabValue: 0 } }} report="FamilyReport"></Liquiloans_tab >
                <div></div>
            </div>
        },
        ]
        return (
            <div>
                {this.state.selectedProduct.id != 8 && <Toolbar handleMenuClick={this.handleMenuClick} productID={15} name={this.state.productName} handleMenuClose={this.handleMenuClose} handleMenuListClick={this.handleMenuListClick} MenuList={MenuList} anchorEl={this.state.anchorEl} loader={this.state.loaderPortfolioSummary} title={labels.ManuHeading.familyPortfolio} isL5Enabled={false} childFunc={this.props.childFunc} data={this.state.selectedProduct.id == 9 ? undefined : this.state.portfolioSummary} className={this.state.selectedProduct.id == 12 ? classes.cardsData : classes.toolbarcard}></Toolbar>
                }
                {this.state.selectedProduct.isTabEnabled ? <div>
                    <div className={classes.root}>
                        <div></div>
                        <div>
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={this.state.value}
                                    onChange={this.handleChange}
                                    indicatorColor="primary"
                                    classes={{ root: classes.tabRoot }}
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-labels="scrollable auto tabs example"
                                    TabIndicatorProps={{ style: { background: window.globalConfig.color.BROWN } }}>
                                    {MenuList.filter((val) => val.id == this.state.productID)[0].List.map((item, index) => {
                                        return <Tab label={item.name} {...a11yProps(index)} />
                                    })
                                    }
                                </Tabs>
                            </AppBar>
                            <TabPanel value={this.state.value} index={0}>
                                <Summary CategoryAllocation={labels.summaryPage.CategoryAllocation}
                                    Deptdata={this.state.splitByCategoryDebt}
                                    splitByInstrument={labels.summaryPage.splitByMarketCap}
                                    EquityFundSector={labels.summaryPage.EquitySector}
                                    flag={labels.ManuHeading.familyPortfolio}
                                    showChart={false}
                                    // report="family"
                                    portfolioSummaryFamily={this.portfolioSummaryFamily}
                                    barChartData={this.state.sectorAllocation}
                                    chart={this.state.categoryChart}
                                    // collapseTable={this.collapseTable}
                                    isLoadingportfolioSummary={this.state.loaderCategoryTable}
                                    isLoadingportfolioChart={this.state.loaderCategoryChart}
                                    categorySummary={this.state.categoryTable}
                                    isLoadingcategorySummary={this.state.loaderCategoryTable}
                                    DonutData={this.state.splitByCategoryEquity}
                                    grandtotal={[labels.messages.grandtotal, this.state.categoryTable[0]?.totalCurrentValue, "", this.state.categoryTable.length == 0 ? "0%" : "100.0%"]}
                                    // grandtotal={[labels.messages.grandtotal, labels.currency.rupeesymbol + this.props.grandtotalsummary, "", this.state.categoryTable.length == 0 ? "0%" : "100.0%"]}
                                    EquityLoading={this.state.loaderSplitByCategoryEquity}
                                    DebtLoading={this.state.loaderSplitByCategoryDebt}
                                    tableHeader={[labels.summaryPage.Category, labels.summaryPage.Amount, "CATEGORY (%)", labels.summaryPage.holdingPercentage]
                                    }
                                />
                            </TabPanel>
                            <TabPanel value={this.state.value} index={1}>
                                <PortfolioXRay
                                    flag={labels.ManuHeading.FamilyReport}
                                    externalClasses={portfolioxraytitle.filter((item) => item.productID == this.state.productID)[0].style}
                                    showtoggle={true}
                                    showdownload={false}
                                    hideCategory={false}
                                    productID={this.state.productID}
                                    // report="familyPortfolio"
                                    hideGrandTotalUnitsColumn={this.state.productID == 10 ? false : true}
                                    Total={labels.ProfitLoss.Total}
                                    MarketValue={labels.Tableheader.MarketValue}
                                    CurrentGain={labels.Tableheader.CurrentGain}
                                    amountLabel={labels.Tableheader.Nav}
                                    units={labels.Tableheader.Quantity}
                                    Subheadingunits={labels.Tableheader.Subheadingprice}
                                    InvestedValue={labels.Tableheader.InvestedValue}
                                    AsOn={labels.Tableheader.AsOn}
                                    totaltext={labels.Tableheader.totaltext}
                                    Sip={labels.Tableheader.Sip}
                                    xray={this.state.portfolioXray}
                                    header={MenuList.filter((item) => item.name == this.state.productName)[0]?.header}
                                    filterSubmit={this.portfolioXrayFilterSumbit}
                                    sortBy={this.portfolioXraySortBy}
                                    List={this.state.portfolioXrayBKP}
                                    selectToggle={false}
                                    isLoadinggroupXray={this.state.loaderPortfolioXray}
                                    logindetails={this.props.logindetails}
                                    dropdowndetails={this.props.dropdowndetails}
                                    title={portfolioxraytitle.filter((item) => item.productID == this.state.productID)[0].title}
                                    schemeList={this.state.portfolioXrayBKP}
                                    grid={classes.fdSixSplitContainer} />
                            </TabPanel>
                            <TabPanel value={this.state.value} index={2}>
                                {/* TRANSACTION */}
                                <Transactionreport header={MenuList.filter((item) => item.name == this.state.productName)[0]?.header}
                                    grid={transactionReportHeader.filter((item) => item.productID == this.state.productID)[0].style}
                                    showdownload={false}
                                    showdropdown={true}
                                    filterHeader={"Scheme"}
                                    ReportList={this.props.financialYearsList.length == 0 ? [] : sortArray([{ label: "All", value: 0, isChecked: false }, ...this.props.financialYearsList.map((item, index) => {
                                        return { ...item, label: item.Year, value: index, isChecked: false }
                                    }),], "ID", "DESC")}
                                    title={transactionReportHeader.filter((item) => item.productID == this.state.productID)[0].title}
                                    flag={labels.ManuHeading.familyPortfolio}
                                    isLoadingtransactionList={this.state.loaderTransaction}
                                    L5ID={this.props.L5ID} maxDate={this.state.endDate} minDate={this.state.startDate}
                                    schemeName={labels.transactionSummary.scripName}
                                    TransactionxcelLoad={this.props.TransactionxcelLoad}
                                    TransactionLoad={this.props.TransactionLoad}
                                    logindetails={this.props.logindetails}
                                    catchmessage={this.props.catchmessage}
                                    date={this.props.date}
                                    userID={this.props.userID}
                                    clientID={this.props.clientID}
                                    sessionID={this.props.sessionID}
                                    transactionList={this.state.transaction}
                                    dropdowntransaction={this.state.transactionBKP}
                                    List={this.state.transactionBKP}
                                    data={this.state.transaction}
                                    filterSubmit={this.transactionFilterSubmit} />
                            </TabPanel>
                            <TabPanel value={this.state.value} index={3}>
                                {/* PROFIT&LOSS */}
                                <Profitlossreport flag={labels.ManuHeading.familyPortfolio}
                                    header={MenuList.filter((item) => item.name == this.state.productName)[0]?.header}
                                    GainValue={labels.ProfitLoss.GainValue}
                                    Units={labels.ProfitLoss.Units}
                                    SellValueLabel={labels.ProfitLoss.SellValue}
                                    PurchaseValueLabel={labels.ProfitLoss.PurchaseValue}
                                    BifurcationOfTaxes="Load"
                                    shortTerm="Short Term"
                                    longTerm="Long Term"
                                    DividendValueLabel={labels.ProfitLoss.DividendValue}
                                    SchemeName={`${MenuList.filter((item) => item.name == this.state.productName)[0]?.header} Name`}
                                    Total={labels.ProfitLoss.Total}
                                    grid={classes.BookedglContainer}
                                    filterSubmit={this.profitLossFilterSubmit}
                                    ProfitLoss={this.state.profitLoss}
                                    profitLossfilter={this.state.profitLossBKP}
                                    isLoadingProfitLoss={this.state.loaderProfitAndLoss}
                                    PurchaseValue={this.props?.PurchaseValue}
                                    DividendValue={this.props?.DividendValue} SellValue={this.props?.SellValue}
                                    glAmount={this.props?.glAmount} GainPercentage={this.props?.GainPercentage}
                                    isProfitLossLoading={this.state.loaderProfitAndLoss}
                                    List={this.state.profitLossBKP} />
                            </TabPanel>
                            {(this.state.selectedProduct.id == 2 || this.state.selectedProduct.id == 3) && <TabPanel value={this.state.value} index={4}>
                                <PortfolioXRay
                                    flag={constants.Common.Bonds}
                                    // classes={classes}
                                    externalClasses={{
                                        portfolioXrayCatrgorySevenSplitContainer: classes.bondsTransferinPortfolioXrayCategory,
                                        portfolioXraySevenSplitContainer: classes.bondsTransferinPortfolioXray
                                    }}
                                    hideCategory={false}
                                    showfilter={false}
                                    showdownload={false}
                                    showsortby={false}
                                    showtoggle={false}
                                    grid={classes.bondSixSplitContainer}
                                    Total={labels.ProfitLoss.Total}
                                    MarketValue={labels.Tableheader.MarketValue}
                                    // CurrentGain={labels.Tableheader.CurrentValue}
                                    // amountLabel={labels.Tableheader.price}
                                    units={labels.Tableheader.Quantity}
                                    Subheadingunits={labels.Tableheader.Subheadingprice}
                                    InvestedValue={labels.Tableheader.InvestedValue}
                                    AsOn={labels.Tableheader.AsOn}
                                    totaltext={labels.Tableheader.totaltext}
                                    Sip={labels.Tableheader.Sip}
                                    xray={this.state.transferIn}
                                    header={"Transaction"}
                                    List={this.state.transferIn}
                                    // filterSubmit={this.props.bondsFilterChange}
                                    // xraysort={this.props.bonds_transferin_xraysort}
                                    // xrayfilter={this.props.bonds_transferin_xrayfilter}
                                    // groupXray={this.props.bonds_transferin_groupXray}
                                    isLoadinggroupXray={this.state.transferInLoader}
                                    // unrealised={this.props.unrealised}
                                    // margetvalue={this.props.margetvalue}
                                    // investedvalue={this.props.investedvalue}
                                    // unrealisedpercentage={this.props.unrealisedpercentage}
                                    // catchmessage={this.props.bonds_transferin_catchmessage}
                                    // grandtotalnetpl={this.props.grandtotalnetpl}
                                    // grandtotaltotaldividend={this.props.grandtotaltotaldividend}
                                    // grandtotalinvestmentvalue={this.props.grandtotalinvestmentvalue}
                                    // grandtotalmarketvalue={this.props.grandtotalmarketvalue}
                                    // grandtotalunrealised={this.props.grandtotalunrealised}
                                    logindetails={this.props.logindetails}
                                    // isXrayLoading={this.props.bonds_transferin_isXrayLoading}
                                    // Load={this.props.Load}
                                    // TransactionLoad={this.props.TransactionLoad}
                                    // xcelLoad={this.props.xcelLoad}
                                    // TransactionxcelLoad={this.props.TransactionxcelLoad}
                                    dropdowndetails={this.props.dropdowndetails}
                                    title={transferIntitle}
                                    schemeList={this.state.transferIn}
                                />
                            </TabPanel>}
                            {this.state.selectedProduct.id == 5 && <TabPanel value={this.state.value} index={4}>
                                <MaturityTracker header={"Issuer"} flag="maturitytracker" filterSubmit={this.maturityFilterSubmit} maturityTrackerBackupList={this.state.maturityTrackerBKP} List={this.state.maturityTrackerList} isLoading={this.state.isLoadingMaturity}></MaturityTracker>
                            </TabPanel>}
                        </div>
                        <div></div>
                    </div>
                </div> :
                    this.state.selectedProduct.component
                }
            </div>
        )
    }
}
const mapStateToProps = state => {
    // //
    return {
        //COMMON PARAM
        sessionID: state.Login.sessionID,
        clientID: state.Login.mainClientID,
        date: state.Login.date,
        userID: state.Login.userID,
        logindetails: state.Login.logindetails,
        financialYearsList: replaceNull(state.Dropdown.dropdowndetails.financialYearsList, "").length != 0 ? state.Dropdown.dropdowndetails.financialYearsList : [],
        L5ID: state.Login.L5ID,
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { getMaturityTrackerDetails },
        dispatch
    );
};
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FamilyReport))