import moment from 'moment';
import labels from '../constants/labels';
import { Asset } from '../mainasset';
import secureStorage from '../securestorage';
import { EncryptURL, replaceNull } from '../commonfunction';
// moment
var axios = require('axios');
export default class Networking {
    static PostApi(url, data = '', authorization, SessionID, allowWithoutLicense) {
        // 
        // try {
        let diffDate = window.globalConfig.licenseDate == "" ? "" : moment
            .duration(
                moment(window.globalConfig.licenseDate, "DD MMM YYYY").diff(
                    moment(moment().format("DD MMM YYYY"), "DD MMM YYYY")
                )
            )
            .asDays()
        let session = secureStorage.getItem("sessionID")
        let authToken = ''
        if (Boolean(authorization)) {
            authToken = authorization
        } else {
            authToken = secureStorage.getItem(`authToken-${session}`)
        }
        let nodeToken = secureStorage.getItem(`nodeToken-${session}`)


        var config = {
            method: 'post',
            url: "/gw",
            //url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${nodeToken}`,
                //'Authorization': `${authToken}`, // for dev
                'gw': EncryptURL(url),
                'token': authToken,
                export: false,
                'Source': replaceNull(secureStorage.getItem('Source'), "NA"),
                'SessionID': !Boolean(SessionID) ? session : SessionID,
                'Access-Control-Allow-Origin': `${window.globalConfig.Domain}`,
                'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload'
            },
            data: data
        }

        return axios(config)
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                return { "status": "F", "message": labels.messages.apierror }
            });

    }
    static GetApi(url, data = '', authorization, SessionID, allowWithoutLicense
    ) {
        let session = secureStorage.getItem('sessionID')
        //const apiurl = ApiValues.BaseUrl + url;
        let authToken = ''
        if (Boolean(authorization)) {
            authToken = authorization
        } else {
            authToken = secureStorage.getItem(`authToken-${session}`)
        }
        let nodeToken = secureStorage.getItem(`nodeToken-${session}`)
        // console.log(authToken,Boolean(authorization),authorization,url)
        //  (apiurl)
        var config = {
            method: 'get',
            url: "/gw",
            //url: url,
            headers: {
                'Authorization': `Bearer ${nodeToken}`,
                //'Authorization': `${authToken}`, // for dev
                'gw': EncryptURL(url),
                'token': authToken,
                export: false,
                'Content-Type': 'application/json',
                'Source': replaceNull(secureStorage.getItem('Source'), "NA"),
                'SessionID': !Boolean(SessionID) ? session : SessionID,
                'Access-Control-Allow-Origin': `${window.globalConfig.Domain}`,
                'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload'
            },
            data: data
        }
        let diffDate = window.globalConfig.licenseDate == "" ? "" : moment
            .duration(
                moment(window.globalConfig.licenseDate, "DD MMM YYYY").diff(
                    moment(moment().format("DD MMM YYYY"), "DD MMM YYYY")
                )
            )
            .asDays()
        // if (!window.globalConfig.isLicenseEnabled || diffDate > 1 || diffDate == "" || allowWithoutLicense) {
        return axios(config)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                // console.log(error,config)
                if (error.response?.status == 440 && secureStorage.getItem(`invalidAuth${session}`) == null) {
                    secureStorage.setItem(`invalidAuth${session}`, "invalid")
                }
                return { "status": "F", "message": labels.messages.apierror }
            });
        // }
        // else {
        //     return returnResponse().then((res) => {
        //         // console.log(res)
        //         return res
        //     })
        // }
    }

}
const returnResponse = () => {
    return new Promise(function (resolve, reject) {
        resolve({ "status": "F", "message": labels.messages.apierror });
    });
}